/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { graphql } from 'gatsby';
import {
  SocialIcon,
  BlogPostMeta,
  BlogsGrid,
  Spacing,
  Text,
  Link,
  Grid,
  Page,
  TwitterIcon,
  Image,
} from 'components';
import { BlogBreadcrumb } from 'partials';
import 'partials/blog-post/prism.min.css';
import { formatTitle } from 'utils';
import { Box, horizontalSpace } from 'design-system';
import { summarySelect } from '../utils';

export default class BlogPostTemplate extends React.Component {
  componentDidMount() {
    const { data, location } = this.props;
    const { title } = data.ghostPost;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'Custom-PageView',
      pageType: 'Blog',
      pagePath: `${location.pathname}`,
      pageTitle: `${formatTitle(title)}`,
    });
  }

  render() {
    const { data } = this.props;
    const post = data.ghostPost;
    const transformedHtml = data.ghostPostExtraData.html;

    const { relatedArticlesHeading } = {
      ...JSON.parse(data.blogSettings.edges[0].node.componentTree),
    }.props;

    const queryStringProps = {
      summary: encodeURIComponent(post.excerpt),
      title: encodeURIComponent(post.title),
      url: encodeURIComponent(
        post.url.replace('rangleio.ghost.io', 'rangle.io/blog'),
      ),
      source: 'Rangle',
      mini: true,
    };

    const socialIconProps = [
      {
        id: 'Twitter',
        platform: 'Twitter',
        linkConfig: {
          props: {
            url: `https://twitter.com/intent/tweet?url=${queryStringProps.url +
              encodeURIComponent(
                '?utm_source=twitter&utm_medium=social-sharing&utm_campaign=blog',
              )}&text=${queryStringProps.summary}`,
          },
        },
      },
      {
        id: 'LinkedIn',
        platform: 'LinkedIn',
        linkConfig: {
          props: {
            url: `https://www.linkedin.com/shareArticle?mini=${
              queryStringProps.mini
            }&url=${queryStringProps.url +
              encodeURIComponent(
                '?utm_source=linkedin&utm_medium=social-sharing&utm_campaign=blog',
              )}&title=${queryStringProps.title}&summary=${
              queryStringProps.summary
            }&source=${queryStringProps.source}`,
          },
        },
      },
      {
        id: 'Facebook',
        platform: 'Facebook',
        linkConfig: {
          props: {
            url: `https://www.facebook.com/sharer.php?u${queryStringProps.url +
              encodeURIComponent(
                `?utm_source=facebook&utm_medium=social-sharing&utm_campaign=blog`,
              )}`,
          },
        },
      },
    ];

    const avatarStyles = {
      maxWidth: ['130px', '92px', '92px', '156px'],
      height: ['130px', '92px', '92px', '156px'],
    };

    const defaultProfileImage =
      'https://rangleio.ghost.io/content/images/2018/09/Rangle-logo-red-RGB--4-_o-1.png';

    const subHeading = (heading, children) => {
      return (
        <Box flex="1" borderTop="1px solid" borderColor="borderDark">
          <Spacing height="xSmall" />
          <Text type="Small Body">{heading}</Text>
          <Spacing height="xxxSmall" />
          {children}
        </Box>
      );
    };

    const transformList = (items, field = 'author') => {
      return (
        <>
          {items.map(({ name, slug }, index) => (
            <>
              <Box display="inline-block">
                <Link
                  linkConfig={{ props: { url: `/blog/${field}/${slug}` } }}
                  type="small"
                  hideArrow
                >
                  {name}
                </Link>
              </Box>
              {index + 1 !== items.length && (
                <Text type="Medium Body Dark" display="inline-block">
                  ,&nbsp;
                </Text>
              )}
            </>
          ))}
        </>
      );
    };

    const authorImage = (name, profileImage) => {
      return (
        <Image
          overideUrl={`https://res.cloudinary.com/rangle/image/fetch/q_auto,f_auto/${profileImage ||
            defaultProfileImage}`}
          alt={name}
          css={`
            img {
              border-radius: 100%;
            }
          `}
          {...avatarStyles}
        />
      );
    };

    const authorInfo = (name, slug, twitter) => {
      const linkConfig = { props: { url: `/blog/author/${slug}` } };

      return (
        <Box>
          <Link linkConfig={linkConfig} hideArrow type="medium">
            {name}
          </Link>
          <Spacing height="xxSmall" />
          <Link linkConfig={linkConfig} type="medium">
            View all articles by this author
          </Link>
          <Spacing height="xxSmall" />
          {twitter && (
            <Link
              linkConfig={{
                props: { url: `https://twitter.com/${twitter}` },
              }}
              hideArrow
            >
              <TwitterIcon height="20px" />
            </Link>
          )}
        </Box>
      );
    };

    const header = (
      <>
        <BlogBreadcrumb />
        <Spacing height="xSmall" />
        <Text type="Large Heading">{post.title}</Text>
        <Spacing height="large" />
        <Grid
          columnRatio="1"
          gutters="large"
          cellBottomPadding="small"
          cells={[
            subHeading(
              'Summary',
              <Text
                type="Small Body"
                color="black"
                className="post-summary"
                dangerouslySetInnerHTML={{
                  __html: summarySelect(post.html, post.plaintext),
                }}
              />,
            ),
          ]}
        />
        <Spacing height="xSmall" />
        <Grid
          columnRatio="1:1:1"
          gutters="large"
          cellBottomPadding="small"
          cells={[
            subHeading('Written by', transformList(post.authors)),
            subHeading(
              'Date published',
              <Text type="Small Body" color="black">
                {post.published_at_pretty}
              </Text>,
            ),
            subHeading('Tags', <>{transformList(post.tags, 'tag')}</>),
          ]}
        />
      </>
    );

    const aboutAuthor = (
      <Box
        borderTop="1px solid"
        borderBottom="1px solid"
        borderColor="borderDark"
      >
        <Spacing height="small" />
        <Text type="Small Heading">
          {`About the ${post.authors.length > 1 ? 'authors' : 'author'}`}
        </Text>
        <Spacing height="small" />
        <Grid
          columnRatio={post.authors.length > 1 ? '1:1' : '1'}
          gutters="large"
          cellBottomPadding="medium"
          cells={post.authors.map(
            ({ slug, name, profile_image: profileImage, twitter }) => (
              <Grid
                key={`about-author-card-${slug}`}
                columnRatio={post.authors.length > 1 ? '1:2' : '1:5'}
                gutters="medium"
                cellBottomPadding="medium"
                cells={[
                  authorImage(name, profileImage),
                  authorInfo(name, slug, twitter),
                ]}
              />
            ),
          )}
        />
        <Spacing height="small" />
      </Box>
    );

    const tagsAndSocials = (
      <>
        <Text type="Medium Body">Tags</Text>
        <Spacing height="xxSmall" />
        {transformList(post.tags, 'tag')}
        <Spacing height="small" />
        <Box display="flex" width="150px" justifyContent="space-between">
          {socialIconProps.map((socialIconProp) => (
            <SocialIcon
              key={`social-media-share-link-id-${socialIconProp.id}-blog-slug-${
                post.slug
              }`}
              {...socialIconProp}
              title={`Share on ${socialIconProp.platform}`}
              mr="medium"
            />
          ))}
        </Box>
      </>
    );

    const stickySocial = (
      <Box
        position="fixed"
        display={['none', 'none', 'block']}
        right="0"
        top={horizontalSpace.xxLarge}
      >
        {socialIconProps.map((socialIconProp) => (
          <SocialIcon
            key={`social-media-share-link-id-${socialIconProp.id}-blog-slug-${
              post.slug
            }`}
            {...socialIconProp}
            title={`Share on ${socialIconProp.platform}`}
            theme="dark"
            bg="darkGrey"
            p="24px"
          />
        ))}
      </Box>
    );

    const mainContent = (
      <Box px={horizontalSpace.layout}>
        <Box className="blog-context">
          <Spacing height="xLarge" />
          {header}
          <Spacing height="large" />
          <Box
            className="post-full-content"
            display="flex"
            flexDirection="column"
            dangerouslySetInnerHTML={{ __html: transformedHtml }}
          />
          <Spacing height="large" />
          {tagsAndSocials}
          <Spacing height="xLarge" />
          {aboutAuthor}
          <Spacing height="xLarge" />
          <Text type="Medium Heading">{relatedArticlesHeading}</Text>
          <Spacing height="large" />
          <BlogsGrid tag={post.primary_tag.slug} slug={post.slug} />
          <Spacing height="xxLarge" />
        </Box>
      </Box>
    );

    return (
      <>
        {stickySocial}
        <Page
          blocks={[mainContent]}
          data={post}
          extraMeta={<BlogPostMeta data={post} />}
        />
      </>
    );
  }
}

export const postQuery = graphql`
  query MainBlogQuery($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
    ghostPostExtraData(slug: { eq: $slug }) {
      html
    }
    blogSettings: allContentfulBlogSettingsTree {
      edges {
        node {
          componentTree
        }
      }
    }
  }
`;
